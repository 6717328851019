import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layout"
import Testimonial from "../testimonial"
import SEO from "../seo"
import { cleanCourseId, cleanCourseTitle } from "../../utils/course-namer"
import COURSE_INFO from "../../data/autogenerated_course_info.json"
import COURSE_REQUIREMENTS from "../../data/requirements.json"
import "../../styles/post.scss"
import Breadcrumb from "../breadcrumb"
import "../term-pills"
import { RequirementsListing } from "../courses/requirements"
import TermPills from "../term-pills"

const TestimonialList = ({ googleSheetData }) => {
  const testimonials = googleSheetData.edges.map((item, key) => (
    <Testimonial key={key} {...item.node} />
  ))
  return (
    <div>
      {testimonials}
      <p className="my-2">
        Add your review by filling out{" "}
        <Link to="/course-review">this form!</Link>
      </p>
    </div>
  )
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allGoogleSheetCourseTestimonials } = data
  const { frontmatter, html } = markdownRemark
  const courseTitle = cleanCourseTitle(frontmatter.title)
  const courseId = frontmatter.id
  const displayCourseId = cleanCourseId(courseId)
  // TODO (https://github.com/PittCSWiki/pittcswiki/issues/56), make the autogenerated create a hash map instead of a list.
  const courseData = COURSE_INFO.courses.filter(({ id }) => id === courseId)[0]
  return (
    <Layout>
      <SEO title={displayCourseId + " - " + frontmatter.title} />
      <Breadcrumb slug={`/courses/${displayCourseId}/`} />
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="frontmatter">
            <h1 className="title">{displayCourseId}</h1>
            <h2 className="sub-title">{courseTitle}</h2>
          </div>
          <TermPills termsMap={courseData.terms_offered} />
          <div className="my-4">
            <RequirementsListing requirements={COURSE_REQUIREMENTS[courseId]} />
          </div>
          <div className="mb-2">
            <span className="label">Course Description</span>
            <span>{courseData.description}</span>
          </div>
          <div className="mb-4">
            <span className="label">Credits:</span>
            <span>{courseData.credits}</span>
          </div>
          {courseData.replaces && (
            <p className="p-2 text-orange-600 bg-orange-200">
              Heads up, this course recently replaced {courseData.replaces} !
            </p>
          )}
          <a
            href={courseData.sci_href}
            target="_blank"
            rel="noopener noreferrer"
          >
            View this course on Pitt's Website
          </a>
          <div
            className="mt-4 blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <h2>Testimonials</h2>
          <div className="mb-4">
            <TestimonialList
              googleSheetData={allGoogleSheetCourseTestimonials}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!, $courseId: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        id
      }
    }
    allGoogleSheetCourseTestimonials(
      filter: { courseId: { eq: $courseId } }
      sort: { fields: year, order: DESC }
    ) {
      edges {
        node {
          courseId
          review
          difficulty
          quality
          prof
          term
          year
        }
      }
    }
  }
`
